<template>
  <div
    class="relative overflow-hidden"
    :class="{
      'is-loading': isLoading && !$refs.img?.complete,
    }"
  >
    <img
      ref="img"
      class="h-full w-full object-fill"
      :loading="isClient ? 'lazy' : undefined"
      :class="imgClasses"
      :src="computedSrc"
      :alt="alt"
      @error="handleImageError"
      @load="handleLoad"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import noImgSrc from "~/assets/images/no-photo.png";

const props = defineProps({
  src: {
    type: String,
  },
  imgClasses: {
    type: String,
  },
  alt: {
    type: String,
  },
});

const isClient = ref(false);
const isLoading = ref(true);
const imageExists = ref(true);

const computedSrc = computed(() => {
  if (!imageExists.value) {
    return noImgSrc;
  }
  return props.src;
});

const handleImageError = () => {
  isLoading.value = false;
  imageExists.value = false;
};

const handleLoad = () => {
  isLoading.value = false;
};

// Enable lazy loading only after hydration
onMounted(() => {
  isClient.value = true;
});
</script>

<style scoped>
.is-loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: linear-gradient(
    90deg,
    theme("colors.gray.200") 0%,
    theme("colors.gray.100") 50%,
    theme("colors.gray.200") 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.4s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
